<template>
    <div>
        <section class="fitlering-buttons">
            <b-button
                class="mr-1"
                :variant="activeUserTab == 1 ? 'primary' : 'outline-primary'"
                @click="updateActiveTab(1)"
                >مستخدمين</b-button
            >
            <b-button
                :variant="activeUserTab == 2? 'primary' : 'outline-primary'"
                @click="updateActiveTab(2)"
                >فعاليات</b-button
            >
        </section>
        
        <section class="reports-table mt-1">
            <component :is="reportTableComponent"></component>
        </section>
        
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import StatusBadge from "@global/components/StatusBadge";
import ReportActivity from "./components/ReportActivity.vue";
import ReportUsers from "./components/ReportUsers.vue";

export default {
    computed: {
        ...mapGetters(["userReportsList", "activityReportsList"]),
        ...mapState({
            accountStatus: (state) => state.users.accountStatus,
            isUserReportUsersTabActive: (state) =>
                state.reports.isUserReportUsersTabActive,
            isActivityReportUsersTabActive: (state) =>
                state.reports.isActivityReportUsersTabActive,
            activeUserTab: (state) =>
                state.reports.activeUserTab,
            reportSubStatuses: (state) =>
                state.reports.reportSubStatuses,
        }),
        reportTableComponent() {
            return this.activeUserTab == 1 ? ReportUsers  : ReportActivity;
        },
    },
    components: {
        StatusBadge,
        ReportActivity,
        ReportUsers
    },
    data: () => ({
        cols: [
            {
                label: " اسم المستخدم",
                field: "accountName",
            },
            {
                label: "البريد الالكتروني/رقم الموبايل",
                field: "userName",
            },
            {
                label: "العمر",
                field: "age",
            },
            {
                label: "تاريخ التسجيل",
                field: "dateCreate",
            },
            {
                label: "حالة الحساب",
                field: "accountStatuses",
            },
            {
                label: "متابعات الفعاليات",
                field: "followingsActivity",
            },
            {
                label: "زيارات الفعاليات",
                field: "visitingsActivity",
            },
            {
                label: "متابعات المستخدمين",
                field: "visitingsAppUser",
            },
            {
                label: "زيارات المستخدمين",
                field: "visitingsAppUser",
            },
            {
                label: "المستخدمين المتابعين",
                field: "followers",
            },
            {
                label: "المستخدمين الزائرين",
                field: "visitors",
            },
            {
                label: "عدد التعليقات",
                field: "comments",
            },
            {
                label: "عدد الإشعارات",
                field: "notifications",
            },
            {
                label: "عدد الوظائف التقدم عليها",
                field: "jobSubmit",
            },
            {
                label: "عدد الاشتراكات",
                field: "sub",
            },
            {
                label: "تاريخ آخر اشتراك",
                field: "endSub",
            },
            {
                label: "حالة الاشتراك",
                field: "subStatuses",
            },
        ],
    }),
    methods: {
        ...mapActions(["getAppUserReports", "getActivityReports"]),
        ...mapMutations(["Update_Report_Active_Tab"]),
        updateActiveTab(tab) {
            this.Update_Report_Active_Tab(tab)
        },
        lastSubscriptionDate(startDate, endDate) {

            let sDate = startDate ? `${new Date(
                startDate
            ).toLocaleDateString()}` : ''

            let eDate = endDate ? `${new Date(
                endDate
            ).toLocaleDateString()}` : ''

            let fullDate = ""

            fullDate = sDate +  '-' + eDate;

            return fullDate;
        },
    },
};
</script>
