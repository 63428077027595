<template>
    <div>
        <section class="report-user-filtering-area mb-2">
            <b-row align-v="center">
                <b-col lg="12">
                    <div class="report-user-filtering-area__filter-title mb-1">
                        فلتر حسب:
                    </div>
                </b-col>

                <b-col
                    lg="3"
                    md="6"
                    xs="6"
                    class="report-user-filtering-area__date-filter-by-order-date-container d-flex"
                >
                    <div
                        class="report-user-filtering-area__order-date-title mr-1"
                    >
                        تاريخ التسجيل:
                    </div>
                    <ek-date-picker
                        v-model="filter.registerDate"
                        range
                        name="تاريخ الطلب"
                        class="report-user-filtering-area__order-date-input"
                    />
                </b-col>

                <b-col
                    lg="3"
                    md="6"
                    xs="6"
                    class="report-user-filtering-area__filter-by-report-status-container d-flex mt-1"
                >
                    <div
                        class="report-user-filtering-area__order-status-title mr-1"
                    >
                        العمر:
                    </div>
                    <ek-input-text
                        v-model="filter.age"
                        name="العمر"
                        placeholder="ادخل العمر"
                        class="report-user-filtering-area__order-status-input"
                    ></ek-input-text>
                </b-col>

                <b-col
                    lg="3"
                    md="6"
                    xs="6"
                    class="report-user-filtering-area__select-filter-by-report-status-container d-flex mt-2"
                >
                    <div
                        class="report-user-filtering-area__order-status-title mr-1"
                    >
                        حالة الحساب:
                    </div>
                    <ek-input-select
                        v-model="filter.accountStatusId"
                        :options="accountStatus"
                        clearable
                        name="statusFtiler"
                        class="report-user-filtering-area__order-status-input"
                    />
                </b-col>

                <b-col
                    lg="3"
                    md="6"
                    xs="6"
                    class="report-user-filtering-area__filter-by-report-status-container d-flex mt-1"
                >
                    <div
                        class="report-user-filtering-area__order-status-title mr-1"
                    >
                        متابعات الفعاليات:
                    </div>
                    <ek-input-text
                        v-model="filter.activityFollowingsCount"
                        name="متابعات الفعاليات"
                        placeholder="ادخل عدد متابعات الفعاليات"
                        class="report-user-filtering-area__order-status-input"
                    ></ek-input-text>
                </b-col>

                <b-col
                    lg="3"
                    md="6"
                    xs="6"
                    class="report-user-filtering-area__filter-by-report-status-container d-flex mt-1"
                >
                    <div
                        class="report-user-filtering-area__order-status-title mr-1"
                    >
                        زيارات الفعاليات:
                    </div>
                    <ek-input-text
                        v-model="filter.activityVisitingsCount"
                        name="زيارات الفعاليات"
                        placeholder="ادخل عدد زيارات الفعاليات"
                        class="report-user-filtering-area__order-status-input"
                    ></ek-input-text>
                </b-col>

                <b-col
                    lg="3"
                    md="6"
                    xs="6"
                    class="report-user-filtering-area__date-filter-by-order-date-container d-flex mt-2"
                >
                    <div
                        class="report-user-filtering-area__order-date-title mr-1"
                    >
                        تاريخ الاشتراك:
                    </div>
                    <ek-date-picker
                        v-model="filter.subscriptionDates"
                        range
                        name="تاريخ الطلب"
                        class="report-user-filtering-area__order-date-input"
                    />
                </b-col>

                <b-col
                    lg="3"
                    md="6"
                    xs="6"
                    class="report-user-filtering-area__title-filter-by-report-status-container d-flex"
                >
                    <div
                        class="report-user-filtering-area__order-status-title mr-1"
                    >
                        عدد الاشتراكات:
                    </div>
                    <ek-input-text
                        v-model="filter.susbscriptionsCount"
                        name="عدد الاشتراكات"
                        placeholder="ادخل عدد الاشتراكات"
                        class="report-user-filtering-area__order-status-input"
                    ></ek-input-text>
                </b-col>

                <b-col
                    lg="3"
                    md="6"
                    xs="6"
                    class="report-user-filtering-area__select-filter-by-report-status-container d-flex mt-1"
                >
                    <div
                        class="report-user-filtering-area__order-status-title mr-1"
                    >
                        حالة الاشتراك:
                    </div>
                    <ek-input-select
                        v-model="filter.subscriptionStatusId"
                        :options="reportSubStatuses"
                        clearable
                        name="statusFtiler"
                        class="report-user-filtering-area__order-status-input"
                    />
                </b-col>
            </b-row>
        </section>

        <section class="user-reports-table">
            <ek-table
                class="mt-1"
                :items="userReportsList.filter(filterUserReports)"
                :columns="cols"
                no_delete
                no_select
                striped
            >
                <template slot="items.dateCreate" slot-scope="{ value }">
                    {{
                        value
                            ? new Date(value)
                                  .toLocaleString("en-UK")
                                  .split(",")
                                  .reverse()
                                  .join(" ")
                            : "-"
                    }}
                </template>

                <template slot="items.accountStatuses" slot-scope="{ value }">
                    <StatusBadge
                        :statusList="accountStatus"
                        :selectedStatusNumber="value"
                    ></StatusBadge>
                </template>

                <template slot="items.endSub" slot-scope="{ props }">
                    {{
                        props.row.startSub && props.row.endSub
                            ? lastSubscriptionDate(
                                  props.row.startSub,
                                  props.row.endSub
                              )
                            : "-"
                    }}
                </template>

                <template slot="items.age" slot-scope="{ value }">
                    {{ value ? value : "" }}
                </template>

                <template slot="items.subStatuses" slot-scope="{ props }">
                    <StatusBadge
                        v-if="props.row.startSub && props.row.endSub"
                        :statusList="reportSubStatuses"
                        :selectedStatusNumber="props.row.subStatuses"
                    ></StatusBadge>
                    <div v-else>
                        {{ "-" }}
                    </div>
                </template>
            </ek-table>
        </section>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import StatusBadge from "@global/components/StatusBadge";
import { dateManipulatoin } from "@core/mixins/ui/dateManipulatoin";

export default {
    mixins: [dateManipulatoin],
    computed: {
        ...mapGetters(["userReportsList"]),
        ...mapState({
            accountStatus: (state) => state.users.accountStatus,
            isUserReportUsersTabActive: (state) =>
                state.reports.isUserReportUsersTabActive,
            isActivityReportUsersTabActive: (state) =>
                state.reports.isActivityReportUsersTabActive,
            activeUserTab: (state) => state.reports.activeUserTab,
            reportSubStatuses: (state) => state.reports.reportSubStatuses,
        }),
    },
    components: {
        StatusBadge,
    },
    data: () => ({
        filter: {
            registerDate: "",
            age: null,
            activityFollowingsCount: null,
            activityVisitingsCount: null,
            subscriptionDate: "",
            susbscriptionsCount: null,
            subscriptionStatusId: null,
            accountStatusId: null,
        },
        cols: [
            {
                label: " اسم المستخدم",
                field: "accountName",
            },
            {
                label: "البريد الالكتروني/رقم الموبايل",
                field: "userName",
            },
            {
                label: "العمر",
                field: "age",
            },
            {
                label: "تاريخ التسجيل",
                field: "dateCreate",
            },
            {
                label: "حالة الحساب",
                field: "accountStatuses",
            },
            {
                label: "متابعات الفعاليات",
                field: "followingsActivity",
            },
            {
                label: "زيارات الفعاليات",
                field: "visitingsActivity",
            },
            {
                label: "متابعات المستخدمين",
                field: "visitingsAppUser",
            },
            {
                label: "زيارات المستخدمين",
                field: "visitingsAppUser",
            },
            {
                label: "المستخدمين المتابعين",
                field: "followers",
            },
            {
                label: "المستخدمين الزائرين",
                field: "visitors",
            },
            {
                label: "عدد التعليقات",
                field: "comments",
            },
            {
                label: "عدد الإشعارات",
                field: "notifications",
            },
            {
                label: "عدد الوظائف التقدم عليها",
                field: "jobSubmit",
            },
            {
                label: "عدد الاشتراكات",
                field: "sub",
            },
            {
                label: "تاريخ آخر اشتراك",
                field: "endSub",
            },
            {
                label: "حالة الاشتراك",
                field: "subStatuses",
            },
        ],
    }),
    created() {
        this.getAppUserReports();
    },
    methods: {
        ...mapActions(["getAppUserReports"]),
        lastSubscriptionDate(startDate, endDate) {
            let sDate = startDate
                ? `${new Date(startDate).toLocaleDateString()}`
                : "";

            let eDate = endDate
                ? `${new Date(endDate).toLocaleDateString()}`
                : "";

            let fullDate = "";

            fullDate = sDate + "-" + eDate;

            return fullDate;
        },
        filterUserReports(el) {
            let [subscriptionStartDate = null, subscriptionEndDate = null] =
                this.filter.subscriptionDate
                    ?.replace("to", "")
                    .replace(" ", "")
                    .split(" ");

            let [registerStartDate = null, registerEndDate = null] =
                this.filter.registerDate
                    ?.replace("to", "")
                    .replace(" ", "")
                    .split(" ");

            return (
                (el.age == this.filter.age || !this.filter.age) &&
                (el.followingsActivity == this.filter.activityFollowingsCount ||
                    !this.filter.activityFollowingsCount) &&
                (el.visitingsActivity == this.filter.activityVisitingsCount ||
                    !this.filter.activityVisitingsCount) &&
                (el.sub == this.filter.susbscriptionsCount ||
                    !this.filter.susbscriptionsCount) &&
                (el.subStatuses == this.filter.subscriptionStatusId ||
                    !this.filter.subscriptionStatusId) &&
                (el.accountStatuses == this.filter.accountStatusId ||
                    !this.filter.accountStatusId) &&
                ((this.setDateTime(new Date(subscriptionStartDate)) >=
                    this.setDateTime(new Date(el.startSub)) &&
                    this.setDateTime(new Date(subscriptionStartDate)) <=
                        this.setDateTime(new Date(el.endSub))) ||
                    (this.setDateTime(new Date(subscriptionEndDate)) >=
                        this.setDateTime(new Date(el.startSub)) &&
                        this.setDateTime(new Date(subscriptionEndDate)) <=
                            this.setDateTime(new Date(el.endSub))) ||
                    this.filter.subscriptionDate.length == 0) &&
                ((this.setDateTime(new Date(el.dateCreate)) >=
                    this.setDateTime(new Date(registerStartDate)) &&
                    this.setDateTime(new Date(el.dateCreate)) <=
                        this.setDateTime(new Date(registerEndDate))) ||
                    this.filter.registerDate.length == 0)
            );
        },
    },
};
</script>

<style lang="scss" scoped>

.report-user-filtering-area {


    align-items: center;

    @media only screen and (max-width: 780px) {
        flex-direction: column;
    }

    &__order-status-title {
        margin-top: 1.4rem;

        font-size: 1.2rem;

        color: #6e6b7b;

        @media only screen and (max-width: 780px) {
            margin-left: 0px;
        }
    }

    &__today-reports-title {
        font-size: 1.5rem;
    }

    &__filter-title {
        font-weight: bold;
        font-size: 1.2rem;

        color: #6e6b7b;
    }

    &__filter-container {
        display: flex;
        gap: 2rem;
    }

    &__filter-by-user-container {
        display: flex;
    }

    &__user-filter-title {
        margin-top: 1.4rem;
        font-size: 1.2rem;

        color: #6e6b7b;
    }

    &__user-filter-input {
        flex: 1;
    }

    &__filter-by-reports-status-container {
        display: flex;
    }

    &__filter-by-order-date-container {
        margin-top: 0.6rem;
    }

    &__date-filter-by-order-date-container {
        margin-top: 2.2rem;
    }

    &__filter-by-report-status-container {
        margin-top: -0.5rem;
    }

    &__select-filter-by-report-status-container {
        margin-top: 0.7rem !important;
    }

    &__title-filter-by-report-status-container {
        margin-top: 0.5rem !important;
    }

    &__order-date-title {
        margin-top: 0.6rem;

        font-size: 1.2rem;

        color: #6e6b7b;

        @media only screen and (max-width: 780px) {
            margin-left: 0px;
        }
    }

    &__order-date-input {
        margin-top: -0.9rem;
        flex: 1;
    }

    &__order-status-type {
        margin-top: 1.3rem;

        font-size: 1.2rem;

        color: #6e6b7b;

        @media only screen and (max-width: 780px) {
            margin-left: 0px;
        }
    }

    &__order-status-input {
        flex: 1;
    }
}
</style>
